@keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: #1F70E9;
    }
  }
  
@-moz-keyframes blink {
from, to {
    color: transparent;
}
50% {
    color: #1F70E9;
}
}

@-webkit-keyframes blink {
from, to {
    color: transparent;
}
50% {
    color: #1F70E9;
}
}

@-ms-keyframes blink {
from, to {
    color: transparent;
}
50% {
    color: #1F70E9;
}
}

@-o-keyframes blink {
from, to {
    color: transparent;
}
50% {
    color: #1F70E9;
}
}

div.surfboard-loading {
    display: flex;
    justify-content: center;
}

h1.loading-blink {
    font-size: 120px;
    color: #1F70E9;
    -webkit-animation: 0.5s blink step-end infinite;
    -moz-animation: 0.5s blink step-end infinite;
    -ms-animation: 0.5s blink step-end infinite;
    -o-animation: 0.5s blink step-end infinite;
    animation: 0.5s blink step-end infinite;
}

div.surfboard-embed {
    height: 100%;
    transition: opacity 2s linear;
}

footer.surfboard-app-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
  }

div.surfboard-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: rgb(40 44 52);
    overflow: hidden;
}

div.surfboard-interactive {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 100%;
    background-color: #FFFFFF;
    
}

div.surfboard-flash {
    display: flex;
    align-items: center;
}

div.surfboard-flash p.surfboard-error-msg {
    display: flex;
    font-size: 12px;
    color: #E11900;
    align-self: center;
    padding: 0 12px 0 12px;
}

div.surfboard-connect-container {
    padding: 12px 12px 12px 12px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

div.surfboard-monitor {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

div.surfboard-send {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

div.surfboard-interactive div.surfboard-terminal {
    box-sizing: border-box;
    background-color: #000000;
    display: inline-block;
}

a.surfboard-footer-link {
    display: flex;
    color: #111111;
    text-decoration: none;
    background-color: #f5f5f5;
}

h5.surfboard-footer-text {
    margin: 10px;
}

svg.surfboard-wordmark {
    height: 12px;
    width: auto;
}